import { emojiHOC } from "accessible-emojis"
import { Link } from "gatsby"
import React, { ReactNode } from "react"
import Button from "react-bootstrap/Button"

import { W_100 } from "@helpers/constants/style"

interface LinkButtonProps {
  to: string
  className?: string
  children: ReactNode
}

export const LinkButton = ({ to, className, children }: LinkButtonProps) => {
  return (
    <Link to={to}>
      <Button className={className}>{children}</Button>
    </Link>
  )
}

interface SpecificLinkButtonProps {
  to: string
}

export const PhysicsLinkButton = ({ to }: SpecificLinkButtonProps) => {
  return (
    <LinkButton to={to} className={W_100}>
      {emojiHOC({
        ariaLabel: "lightning",
        emoji: "⚡",
      })}{" "}
      Practice Physics
    </LinkButton>
  )
}

export const EnglishLinkButton = ({ to }: SpecificLinkButtonProps) => {
  return (
    <LinkButton to={to} className={W_100}>
      {emojiHOC({
        ariaLabel: "book",
        emoji: "📚",
      })}{" "}
      Practice English
    </LinkButton>
  )
}
