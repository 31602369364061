import React from "react"

import Character from "@components/Character"
import ContentContainer from "@components/ContentContainer"
import { Dungeon } from "@components/Dungeon"
import { Img } from "@components/Img"

import { TitleThenDescription } from "@views/Homepage/HowItWorks/TitleThenDescription"

import towerSrc from "@images/general/tower.svg"
import skeletonFighterSrc from "@images/monsters/skeletons/skeleton-fighter-min.png"

import { tower, monsterContainer, monster } from "./h.module.scss"

const PanelItemWrapper = ({ children }: any) => {
  return (
    <div className="col-12 col-md-4 text-center mb-4 mb-md-0">{children}</div>
  )
}

export default () => {
  return (
    <ContentContainer rowConstrained={true} sectionClassesExtra="section pt-0">
      <div className="col-12 d-flex align-items-center justify-content-center text-center mb-3">
        <Img className={tower} src={towerSrc} />
        <h2 className="mb-0 pl-2">How it works</h2>
      </div>

      <PanelItemWrapper>
        <Character className="rounded" />
        <TitleThenDescription
          title="Create your avatar"
          description="See yourself improving question by question."
        ></TitleThenDescription>
      </PanelItemWrapper>

      <PanelItemWrapper>
        <Dungeon className="rounded"></Dungeon>

        <TitleThenDescription
          title="Select something to practice"
          description={
            <>
              Explore <i>dungeons</i> by topic
            </>
          }
        ></TitleThenDescription>
      </PanelItemWrapper>

      <PanelItemWrapper>
        <div className={monsterContainer + " rounded"}>
          <Img className={monster} src={skeletonFighterSrc} />
        </div>
        <TitleThenDescription
          title="Practice questions"
          description="Face off against questions of increasing difficulty"
        ></TitleThenDescription>
      </PanelItemWrapper>
    </ContentContainer>
  )
}
