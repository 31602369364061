import React from "react"

import { AWSInnovator } from "./AWSInnovator"
import { Hero } from "./Hero"
import HowItWorks from "./HowItWorks"
import WhyPastPapers from "./WhyPastPapers"

export const HomepageView = () => {
  return (
    <>
      <Hero />
      <HowItWorks />
      <AWSInnovator />
      <WhyPastPapers />
    </>
  )
}
