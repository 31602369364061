import { Link } from "gatsby"
import React from "react"

import { navItemClasses } from "./constants"

export const NavLink = ({ to, label }: { to: string; label: string }) => {
  return (
    <Link to={to} className={navItemClasses}>
      {label}
    </Link>
  )
}
