import { OutboundLink } from "gatsby-plugin-gtag"
import React from "react"

type GoogleFormLinkProps = {
  text: string
  href: string
  className?: string
}

export const GoogleFormLink = ({
  text = "Leave Feedback (after demos)",
  href,
  className,
}: GoogleFormLinkProps) => {
  return (
    <OutboundLink href={href} target="__blank" className={className}>
      {text}
    </OutboundLink>
  )
}

type GoogleFormLink1Props = {
  text?: string
  className?: string
}

export const GoogleFormLink1 = ({
  text = "Leave Feedback (after demos)",
  className,
}: GoogleFormLink1Props) => {
  return (
    <GoogleFormLink
      className={className}
      href="https://forms.gle/U9HQ67LYMWVCPi446"
      text={text}
    />
  )
}

export const GoogleFormLinkGeneralFeedback = ({
  text = "Leave Feedback",
  className,
}: GoogleFormLink1Props) => {
  return (
    <GoogleFormLink
      className={className}
      href="https://forms.gle/Rijsx1JvocX3vKfW9"
      text={text}
    />
  )
}
