import React from "react"

interface Props {
  href: string
  children: React.ReactNode
}

export const NewTabLink = ({ href, children }: Props) => (
  <a href={href} target="__blank">
    {children}
  </a>
)
