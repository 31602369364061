import React from "react"
import Col from "react-bootstrap/Col"

import { CenteredWrapper } from "@components/Containers/positioning-wrappers"
import ContentContainer from "@components/ContentContainer"
import { Img } from "@components/Img"
import { NewTabLink } from "@components/NewTabLink"

import { TEXT_CENTER, PL_2, MB_4 } from "@helpers/constants/style"

import AWSSrc from "@images/icons/companies/aws-innovator-badge.jpg"

export const AWSInnovator = () => {
  return (
    <ContentContainer rowConstrained={true} sectionClassesExtra="section">
      <Col>
        <h2 className={`${PL_2} ${TEXT_CENTER}`}>
          A proud member of the AWS EdStart Program
        </h2>
        <CenteredWrapper className={MB_4}>
          <NewTabLink href="https://aws.amazon.com/education/edstart/">
            <Img alt="AWS EdStart Innovator" src={AWSSrc} />
          </NewTabLink>
        </CenteredWrapper>
        <p>
          In addition to being powered by AWS, EduCats is proud to say we're now
          being supported with AWS's EdStart program as an innovation tier
          member.
        </p>
      </Col>
    </ContentContainer>
  )
}
