import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import {
  PhysicsLinkButton,
  EnglishLinkButton,
} from "@components/Buttons/link-button"
import { LeftAlignedVerticallyCenteredWrapper } from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import { LINK_APP } from "@helpers/constants/links"
import {
  QUERY_PARAM_SUBJECT,
  QUERY_PARAM_VALUE_PHYSICS,
  QUERY_PARAM_VALUE_ENGLISH,
} from "@helpers/constants/query-params"
import { W_100, MY_2 } from "@helpers/constants/style"

export const CTALinks = () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <p>
            EduCats has licensed the{" "}
            <strong>official HSC past paper content</strong> for Physics,
            Mathematics Standard 2, and Mathematics Advanced.
          </p>
          <p>Practice for Physics today (~50 multiple choice questions)</p>
          <p>More content on the way. Show your support by leaving feedback.</p>
        </Col>
        <Col xs={6}>
          <PhysicsLinkButton
            to={`${LINK_APP}?${QUERY_PARAM_SUBJECT}=${QUERY_PARAM_VALUE_PHYSICS}`}
          />
        </Col>
        <Col xs={6}>
          <EnglishLinkButton
            to={`${LINK_APP}?${QUERY_PARAM_SUBJECT}=${QUERY_PARAM_VALUE_ENGLISH}`}
          />
        </Col>
        <Col xs={12}>
          <LeftAlignedVerticallyCenteredWrapper className={MY_2}>
            <GoogleFormLinkGeneralFeedback text="Leave Feedback" />
          </LeftAlignedVerticallyCenteredWrapper>
        </Col>
      </Row>
    </Container>
  )
}
