import React from "react"

interface Props {
  src: string
  alt?: string
  className?: string
  style?: {
    [key: string]: string | number
  }
}

export const Img = ({ alt = "", src, className, style }: Props) => (
  <img alt={alt} src={src} className={className} style={style} loading="lazy" />
)
