import React from "react"

import { NavLink } from "@components/nav-item/nav-link"
import { BaseNavbar } from "@components/navbar"

import { LINK_APP } from "@helpers/constants/links"

export const StaticNavbar = () => {
  return (
    <BaseNavbar>
      {/* <NavLink to={LINK_BLOG} label="Blog" /> */}
      <NavLink to={LINK_APP} label="App" />
    </BaseNavbar>
  )
}
