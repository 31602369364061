import React from "react"

type PropsType = {
  title: string | JSX.Element
  description: string | JSX.Element
}

export const TitleThenDescription = ({ title, description }: PropsType) => {
  return (
    <>
      <h3 className="fs-18px mt-2 mb-0">{title}</h3>
      <p className="mb-0">{description}</p>
    </>
  )
}
