import React from "react"

import SEO from "@components/SEO/seo"
import { StaticNavbarLayout } from "@components/layouts/static-navbar-layout"

import { HomepageView } from "@views/Homepage"

export default () => (
  <StaticNavbarLayout>
    <SEO
      title="EduCats - Home"
      keywords={[
        `EduCats`,
        `past paper practice`,
        `Australian Syllabus`,
        `HSC past exam questions`,
      ]}
    />
    <HomepageView />
  </StaticNavbarLayout>
)
