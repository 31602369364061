import { classnames } from "classnames-joiner"
import React from "react"

import { Img } from "@components/Img"
import { statusOk } from "@components/status-avatar/q.module.scss"

import CharacterSrc from "@images/knight-character.png"

import { container, img } from "./c.module.scss"

interface CharacterProps {
  className?: string
}

const Character = (props: CharacterProps) => (
  <div
    className={classnames([
      statusOk,
      container,
      "d-flex align-items-center justify-content-center p-2",
      props.className,
    ])}
  >
    <Img className={`${img}`} src={CharacterSrc} alt="" />
  </div>
)

export default Character
