import React from "react"

import { FullHeightColumnSpacedWrapper } from "@components/Containers/positioning-wrappers"
import { Footer } from "@components/footer"
import { StaticNavbar } from "@components/static-navbar"

import "@styles/style.scss"

import { ContentSection } from "./content-section"
import { LayoutType } from "./types"

export const StaticNavbarLayout = ({
  hasNavBar = true,
  children,
  hasFooter = true,
  contentSectionClassName,
}: LayoutType) => (
  <>
    <FullHeightColumnSpacedWrapper>
      {hasNavBar && <StaticNavbar />}
      <ContentSection className={contentSectionClassName}>
        {children}
      </ContentSection>
      {hasFooter && <Footer />}
    </FullHeightColumnSpacedWrapper>
  </>
)
