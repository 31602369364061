import React from "react"

import ContentContainer from "@components/ContentContainer"
import { Img } from "@components/Img"

import examSrc from "@images/general/exam-scroll-swords.svg"
import scrollGoldenSrc from "@images/general/scroll-golden.svg"

import { CTALinks } from "../CTALinks"
import { scroll, exam } from "./w.module.scss"

export default () => {
  return (
    <ContentContainer rowConstrained={true} sectionClassesExtra="section pt-0">
      <div className="col-12 d-flex align-items-center justify-content-center text-center mb-3">
        <Img className={scroll} src={scrollGoldenSrc} alt="tower" />
        <h2 className="mb-0 pl-2">Why past paper practice?</h2>
      </div>
      <article className="col-12 col-md-6">
        <h3 className="fs-18px">
          Practicing exam style questions places you in the best standing for
          your own exams.
        </h3>
        <p>
          Ever experience feeling lost in an exam, even though you completed
          your textbook exercises, and chapter reviews? There's a reason.
        </p>
        <p>
          Exam paper questions are different. They're designed to obscure the
          concepts you've learnt to test how well you can decyper them.
        </p>
        <p>
          The best way to learn that skill is to practice on the past papers
          that have come before.
        </p>
      </article>
      <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
        <Img className={exam + " py-4"} alt="exam" src={examSrc} />
      </div>
      <div className="col-12 col-md-6">
        <CTALinks />
      </div>
    </ContentContainer>
  )
}
