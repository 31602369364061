import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { Img } from "@components/Img"

import { CTALinks } from "@views/Homepage/CTALinks"

import { W_100, SECTION, D_BLOCK, TEXT_CENTER } from "@helpers/constants/style"

import LogoSrc from "@images/educats-logo.png"

import { logo } from "./h.module.scss"

export const Hero = () => {
  return (
    <Container className={SECTION}>
      <Row>
        <Col xs={12} md={4}>
          <Img
            alt="EduCats"
            src={LogoSrc}
            className={`${logo} ${W_100} ${D_BLOCK}`}
          />
          <h2 className={TEXT_CENTER}>EduCats</h2>
        </Col>
        <Col xs={12} md={8}>
          <h1>Engaging past paper style practice</h1>
          <p>
            Prepare for your HSC exams in an immersive, game like experience.
            Visualise your knowledge <i>leveling up</i> as you take down{" "}
            <i>mobs</i> of past paper style questions of increasing difficulty.
          </p>
          <CTALinks />
        </Col>
      </Row>
    </Container>
  )
}
