import { classnames } from "classnames-joiner"
import React from "react"

import { Img } from "@components/Img"

import DungeonSrc from "@images/skeleton-dungeon.png"

import { container, img } from "./d.module.scss"

interface DungeonProps {
  className?: string
}

export const Dungeon = (props: DungeonProps) => (
  <div
    className={classnames([
      container,
      "d-flex align-items-center justify-content-center p-2",
      props.className,
    ])}
  >
    <Img className={img} src={DungeonSrc} alt="" />
  </div>
)
